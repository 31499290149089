import ChartDataLabels from 'chartjs-plugin-datalabels';
import { round } from '../utils/format';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useRef } from 'react';
import { DEFAULT_DATE_RANGE } from '../hooks/useUpdateDateRange';
import { getMonth, getShortDate, getWeekNumber } from '../utils/date';
import { ONE_DAY } from '../utils/consts';

export const BarChart = ({ dayData, scope, amount, showGrid, updateDateRange }) => {
    const chartRef = useRef();

    const { clickUpdates, labels, data: dataSolar } = getChartData(dayData, scope, 'solar', amount);
    const { data: dataGrid } = getChartData(dayData, scope, 'grid', amount);
    const { data: dataExcess } = getChartData(dayData, scope, 'excess', amount);

    const dataSolarExcessCapped = dataSolar.map((solar, index) => solar - (dataExcess[index] ?? 0));

    const chartData = {
        labels,
        datasets: [
            {
                id: 'solar',
                backgroundColor: 'rgb(223,124,55)',
                data: dataSolarExcessCapped
            },
            {
                id: 'excess',
                backgroundColor: 'rgb(245,178,67)',
                data: dataSolar
            },
            showGrid
                ? {
                      id: 'grid',
                      backgroundColor: 'rgba(159,198,245)',
                      data: dataGrid.map((grid, index) => grid + (dataSolar[index] ?? 0) - (dataExcess[index] ?? 0))
                  }
                : {}
        ]
    };

    const onClick = event => {
        if (!clickUpdates) return;
        const update = clickUpdates[getElementAtEvent(chartRef.current, event)[0].index];
        //setLogData([]); // unload previous date to prevent flickering
        updateDateRange(update);
    };

    return (
        <Bar
            ref={chartRef}
            height="400px"
            datasetIdKey="id"
            data={chartData}
            plugins={[ChartDataLabels]}
            options={{
                animation: { duration: 0 },
                responsive: true,
                maintainAspectRatio: false,
                fill: true,
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        ticks: {
                            callback: value => value + ' kWh'
                        }
                    }
                },
                plugins: {
                    legend: false,
                    datalabels: {
                        display: amount < 15,
                        anchor: 'end',
                        align: 'bottom',
                        formatter: (value, context) => {
                            if (context.dataset.id === 'grid') {
                                return round(dataGrid[context.dataIndex], 1);
                            }

                            if (context.dataset.id === 'excess') {
                                if (!showGrid && dataSolarExcessCapped[context.dataIndex] / value < 0.87) {
                                    return round(value - dataSolarExcessCapped[context.dataIndex], 1);
                                }

                                return '';
                            }

                            return round(value, 1);
                        },
                        rotation: chartData.datasets[0].data.length < 10 ? 0 : -90,
                        color: 'white',
                        font: {
                            size: 13,
                            weight: 'bold'
                        }
                    }
                }
            }}
            onClick={onClick}
        />
    );
};

const getChartData = (dayData, scope, wattType, amount) => {
    switch (scope) {
        case 'months':
            return getChartDataMonths(dayData, wattType);
        case 'weeks':
            return getChartDataWeeks(dayData, wattType);
        case 'days':
        default:
            return getChartDataDays(dayData, wattType, amount);
    }
};

const getChartDataDays = (dayData, wattType, amount) => {
    const dataSlice = dayData.slice(-amount);

    return {
        clickUpdates: dataSlice.map(item => ({ ...DEFAULT_DATE_RANGE, timestamp: item.timestamp })),
        labels: dataSlice.map(item => getShortDate(item.timestamp)),
        data: dataSlice.map(item => item[wattType] / 1_000)
    };
};

const getChartDataWeeks = (dayData, wattType) => {
    const weeks = 4;
    let indexOffset = null;

    const clickUpdates = [];
    const labels = [];
    const data = [];

    [...dayData.slice(-(weeks * 7))].reverse().forEach(item => {
        const weekNumber = getWeekNumber(item.timestamp);

        if (indexOffset === null) indexOffset = weekNumber;

        const index = indexOffset - weekNumber;
        const power = item[wattType] / 1_000;

        let clickActionTimestamp = item.timestamp;
        while (getWeekNumber(clickActionTimestamp + ONE_DAY) === weekNumber) clickActionTimestamp += ONE_DAY;

        if (data[index]) {
            data[index] += power;
        } else {
            data[index] = power;
            labels[index] = `KW ${weekNumber}`;
            clickUpdates[index] = { amount: 7, scope: 'day', timestamp: clickActionTimestamp };
        }

        //console.log(weekNumber, getDbDate(item.timestamp), round(power, 1), index);
    });

    return {
        clickUpdates: clickUpdates.reverse(),
        labels: labels.reverse(),
        data: data.map(item => Math.round(item * 10) / 10).reverse()
    };
};

const getChartDataMonths = (dayData, wattType) => {
    let indexOffset = null;

    const labels = [];
    const data = [];

    [...dayData].reverse().forEach(item => {
        const month = getMonth(item.timestamp);

        if (indexOffset === null) indexOffset = month;

        const index = indexOffset - month;
        const power = item[wattType] / 1_000;

        if (data[index]) {
            data[index] += power;
        } else {
            data[index] = power;
            labels[index] = new Intl.DateTimeFormat('de-DE', { month: 'long' }).format(item.timestamp * 1_000);
        }

        //console.log(month, getDbDate(item.timestamp), round(power, 1), index);
    });

    return {
        labels: labels.reverse(),
        data: data.map(item => Math.round(item * 10) / 10).reverse()
    };
};
