import { LOG_TIME } from './consts';

const getDateTokens = timestamp => {
    const date = timestamp ? new Date(timestamp * 1_000) : new Date();
    const timezoneOffset = 0; //getTimezoneOffsetHours(date);

    return {
        hours: addLeadingZero(date.getHours() - timezoneOffset >= 0 ? date.getHours() - timezoneOffset : 23),
        minutes: addLeadingZero(date.getMinutes()),
        seconds: addLeadingZero(date.getSeconds()),
        day: addLeadingZero(date.getDate()),
        month: addLeadingZero(date.getMonth() + 1),
        year: addLeadingZero(date.getFullYear())
    };
};

export const formatDate = timestamp => {
    const { hours, minutes } = getDateTokens(timestamp);
    return `${hours}:${minutes}`;
};

export const getDbDate = timestamp => {
    const { year, month, day } = getDateTokens(timestamp);
    return `${year}-${month}-${day}`;
};

const getDateAtHour = (timestamp, hour) => {
    const { year, month, day } = getDateTokens(timestamp);
    return `${year}-${month}-${day}T${addLeadingZero(hour)}:00:00Z`;
};

export const getShortDate = timestamp => {
    const { month, day } = getDateTokens(timestamp);
    return `${day}.${month}.`;
};

export const getFullDate = timestamp => {
    const { year, month, day, hours, minutes } = getDateTokens(timestamp);
    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const getStartDateTimestamp = (timestamp, hour = LOG_TIME.START) => {
    return new Date(getDateAtHour(timestamp, hour)).getTime() / 1_000;
};

export const getEndDateTimestamp = (timestamp, hour = LOG_TIME.END) => {
    return new Date(getDateAtHour(timestamp, hour)).getTime() / 1_000;
};

export const getMonth = timestamp => {
    const date = new Date(timestamp * 1_000);

    return addLeadingZero(date.getMonth() + 1);
};

export const parseDbDate = date => {
    if (!date) return;

    const [year, month, day] = date.split('-');

    if (!year || !month || !day) return;

    return new Date(`${year}-${month}-${day}T00:00:00Z`).getTime() / 1_000;
};

export const getWeekNumber = timestamp => {
    // Copy date so don't modify original
    const d = new Date(timestamp * 1_000);
    //console.log(getDbDate(d.getTime() / 1_000));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    return addLeadingZero(Math.ceil(((d - yearStart) / 86400000 + 1) / 7));
};

const addLeadingZero = date => date.toString().padStart(2, '0');

export const getTimezoneOffsetHours = date => ((date ?? new Date()).getTimezoneOffset() / 60) * -1;
export const getUtcTimestamp = () => Date.now() / 1_000; /*- (new Date().getTimezoneOffset() * 60 * 60) / 1_000*/
