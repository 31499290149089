export const ACCESS_TOKEN = 'ukcxppmpHqtpURqVPCdma7yp';

export const BASE_URL_REMOTE = 'https://solar.familie-hanssen.de:8083/api';
export const BASE_URL_LOCAL = 'http://localhost:8083/api';

export const KWH_PRICE = 22.37; // [Cent]
const KWH_CONSUMPTION = 5_000; // [kWh / year]

export const MAX_POWER = 600; // [W] max output of solar panels

export const LOG_TIME = {
    START: 5,
    END: 21
};

export const ONE_DAY = 24 * 60 * 60;
