import { round } from '../utils/format';
import { MAX_POWER } from '../utils/consts';
import { useLayoutEffect, useMemo } from 'react';
import { hasSun } from '../utils/sunrises';
import { GaugeTable } from './GaugeTable';
import { Grid } from 'semantic-ui-react';
import { GaugeWrapper } from './GaugeWrapper';

export const Gauge = ({ data, dataDay }) => {
    const { data: dataWatt, resolution } = data.watt ?? { data: [], resolution: 0 };

    let current = { solar: null, grid: null, excess: null };

    if (dataWatt.length > 1 && dataDay.length > 1) {
        const timestamp = new Date(Math.ceil(Date.now() / 1_000 / (5 * 60)) * (5 * 60)).getTime();
        const timestampMaxAge = timestamp - resolution * 60 * 2; // max 2 group ages old

        const { timestamp: timestampSolar, solar } = dataWatt.filter(item => item.solar !== null).at(-1) ?? {};
        const {
            timestamp: timestampGrid,
            grid,
            excess
        } = dataWatt.filter(item => item.grid !== null || item.excess !== null).at(-1) ?? {};

        current = {
            solar: timestampSolar >= timestampMaxAge ? (solar / resolution) * 60 : null,
            grid: timestampGrid >= timestampMaxAge ? (grid / resolution) * 60 : null,
            excess: timestampGrid >= timestampMaxAge ? (excess / resolution) * 60 : null
        };
    }

    const currentPowerPercent = Math.min(current.solar / MAX_POWER, 1);
    const showCurrentData = current.solar && hasSun();

    console.log(current, hasSun());

    const needleColor = showCurrentData
        ? currentPowerPercent >= 0.3
            ? '#41b91a'
            : currentPowerPercent >= 0.1
            ? '#F5CD19'
            : '#EA4228'
        : '#e7e7e7';

    useLayoutEffect(() => {
        const suffix =
            current.solar && current.grid ? `(${round(current.solar, 0)} / ${round(current.grid, 0)} W)` : '';
        document.title = `Solar Monitor ${suffix}`;
    }, [current]);

    const gaugeWrapper = useMemo(
        () => (
            <GaugeWrapper
                showCurrentData={showCurrentData}
                currentPowerPercent={currentPowerPercent}
                needleColor={needleColor}
            />
        ),
        [currentPowerPercent, needleColor, showCurrentData]
    );

    const GridRow = (
        <Grid.Row>
            <Grid.Column>{gaugeWrapper}</Grid.Column>
            <Grid.Column>
                {<GaugeTable data={data} dayData={dataDay} current={current} showCurrentData={showCurrentData} />}
            </Grid.Column>
        </Grid.Row>
    );

    return (
        <>
            <Grid columns={1} centered={true} className="tablet computer only">
                {GridRow}
            </Grid>
            <Grid columns={2} centered={true} className="mobile only">
                {GridRow}
            </Grid>
        </>
    );
};
