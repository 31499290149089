import { Line } from 'react-chartjs-2';
import { formatDate } from '../utils/date';
import { MAX_POWER } from '../utils/consts';

export const LineChart = ({ data, scaleChart, showLabelsX }) => {
    const chartData =
        data && data.data
            ? {
                  labels: data.data.map(item => formatDate(item.timestamp)),
                  datasets: [
                      {
                          id: 1,
                          lineTension: 0.3,
                          borderWidth: 0,
                          backgroundColor: 'rgba(223,124,55,1)',
                          spanGaps: false,
                          data: data.data.map(({ solar, excess }) =>
                              solar !== null ? ((solar - excess) * 60) / data.resolution : NaN
                          )
                      },
                      {
                          id: 2,
                          lineTension: 0.3,
                          borderWidth: showLabelsX ? 2 : 1,
                          borderColor: 'rgba(223,124,55,1)',
                          backgroundColor: 'rgba(245,178,67,0.3)',
                          spanGaps: false,
                          data: data.data.map(({ solar }) => (solar !== null ? (solar * 60) / data.resolution : NaN))
                      },
                      {
                          id: 3,
                          lineTension: 0.3,
                          borderWidth: 0,
                          backgroundColor: 'rgba(159,198,245,0.8)',
                          spanGaps: false,
                          data: data.data.map(({ solar, grid, excess }) =>
                              grid !== null ? ((solar - excess + grid) * 60) / data.resolution : NaN
                          )
                      }
                  ]
              }
            : { labels: [], datasets: [] };

    return (
        <Line
            datasetIdKey="id"
            data={chartData}
            options={{
                animation: { duration: 0 },
                responsive: true,
                maintainAspectRatio: false,
                fill: true,
                radius: 0,
                scales: {
                    x: {
                        display: showLabelsX
                    },
                    y: {
                        min: 0,
                        max: scaleChart ? MAX_POWER : undefined,
                        ticks: {
                            callback: value => value + ' W'
                        }
                    }
                },
                plugins: {
                    legend: false
                }
            }}
        />
    );
};
