import { round } from '../utils/format';
import { KWH_PRICE, MAX_POWER } from '../utils/consts';
import { Header } from 'semantic-ui-react';

const INVEST_COST =
    700 + // Solar Panels + Wechselrichter
    80; // Installation (Kabel, Material)

const sumBy = (data, value) => data.reduce((sum, item) => sum + (item[value] ?? 0), 0) / 1_000;

export const GaugeTable = ({ data, dayData, current, showCurrentData }) => {
    if (!data.watt) return null;

    const firstTimestamp = data.watt.data.at(0).timestamp;
    const lastTimestamp = data.watt.data.at(-1).timestamp;

    // 1 or multiple days depending on selected date filter
    const currentDayData = dayData.filter(day => day.timestamp >= firstTimestamp && day.timestamp < lastTimestamp);

    const solarWh = currentDayData.reduce((sum, day) => sum + day.solar, 0);
    const gridWh = currentDayData.reduce((sum, day) => sum + day.grid, 0);
    const excessWh = currentDayData.reduce((sum, day) => sum + day.excess, 0);

    const solar = (solarWh ?? 0) / 1_000; // [kWh]
    const grid = (gridWh ?? 0) / 1_000; // [kWh]
    const excess = (excessWh ?? 0) / 1_000; // [kWh]

    const currentPowerPercent = Math.min(current.solar / MAX_POWER, 1);
    const maxW = (Math.max(...data.watt.data.map(item => item.solar), 0) * 60) / data.watt.resolution;

    const currentSolarKw = current.solar / 1_000; // [Wh] -> [kWh]
    const currentGridKw = current.grid / 1_000; // [Wh] -> [kWh]
    const currentExcessKw = current.excess / 1_000; // [Wh] -> [kWh]
    const currentIncome = ((currentSolarKw - currentExcessKw) * KWH_PRICE) / 60; // [Cent/h]

    const dayOwnConsumptionSum = solar - excess; // [kWh]

    const overallSolarSumKwh = sumBy(dayData, 'solar');
    const overallExcessSumKwh = sumBy(dayData, 'excess');
    const overallIncome = ((overallSolarSumKwh - overallExcessSumKwh) * KWH_PRICE) / 100; // [Euro]

    // last 365 days without current day (without first 20 days, where installation was not complete)
    const dayDataYearly = dayData.slice(20).slice(-366, -1);
    const sumSolarKwhYearly = sumBy(dayDataYearly, 'solar');
    const avgSolarDaily = sumSolarKwhYearly / dayDataYearly.length; // [kWh]
    const avgDailyIncome = (avgSolarDaily * KWH_PRICE) / 100; // [Euro]
    const remainingCost = INVEST_COST - overallIncome;

    const dayAutarky = (solar - excess) / (solar - excess + grid);
    const dayOwnConsumption = 1 - excess / solar;

    const dayDataYearlyFiltered = dayDataYearly.filter(item => item.solar && item.grid);
    const sumSolarKwhYearlyFiltered = sumBy(dayDataYearlyFiltered, 'solar');
    const sumGridKwhYearlyFiltered = sumBy(dayDataYearlyFiltered, 'grid');
    const sumExcessKwhYearlyFiltered = sumBy(dayDataYearlyFiltered, 'excess');

    const yearlyAutarky =
        (sumSolarKwhYearlyFiltered - sumExcessKwhYearlyFiltered) /
        (sumSolarKwhYearlyFiltered + sumGridKwhYearlyFiltered);
    const yearlyOwnConsumption = 1 - sumExcessKwhYearlyFiltered / sumSolarKwhYearlyFiltered;

    return (
        <>
            <Header size="medium">Solar</Header>
            <table className="ui definition table gauge unstackable">
                <tbody>
                    <tr>
                        <th>Aktuell</th>
                        {showCurrentData ? (
                            <>
                                <td className="data-col">{round(currentIncome, 2)}</td>
                                <td className="unit">ct/h</td>
                                <td className="data-col second-col">{round(currentPowerPercent * 100)}</td>
                                <td className="unit">%</td>
                            </>
                        ) : (
                            <>
                                <td className="data-col">-</td>
                                <td className="unit" />
                                <td className="data-col second-col">-</td>
                                <td className="unit" />
                            </>
                        )}
                    </tr>
                    <tr>
                        <th>Max</th>
                        <td className="data-col">{round(maxW)}</td>
                        <td className="unit">W</td>
                        <td className="data-col second-col">{round((maxW / MAX_POWER) * 100)}</td>
                        <td className="unit">%</td>
                    </tr>
                    <tr>
                        <th>Σ</th>
                        <td className="data-col">{round(dayOwnConsumptionSum, 1)}</td>
                        <td className="unit">kWh</td>
                        <td className="data-col second-col">{round((dayOwnConsumptionSum * KWH_PRICE) / 100, 2)}</td>
                        <td className="unit">€</td>
                    </tr>
                    <tr>
                        <th>Autark</th>
                        <td className="data-col">{round(dayAutarky * 100)}</td>
                        <td className="unit">%</td>
                        <td className="data-col second-col" title="Eigenverbrauch">
                            {round(dayOwnConsumption * 100)}
                        </td>
                        <td className="unit">%</td>
                    </tr>
                </tbody>
            </table>

            <Header size="medium">Netz</Header>
            <table className="ui definition table gauge unstackable">
                <tbody>
                    <tr>
                        <th>Aktuell</th>
                        {showCurrentData ? (
                            currentGridKw >= 0 ? (
                                <>
                                    <td className="data-col second-col">{round(currentGridKw * 1_000)}</td>
                                    <td className="unit">W</td>
                                    <td className="data-col">{round(currentGridKw * KWH_PRICE)}</td>
                                    <td className="unit">ct/h</td>
                                </>
                            ) : (
                                <>
                                    <td className="data-col second-col">-{round(currentExcessKw * 1_000)}</td>
                                    <td className="unit">W</td>
                                    <td className="data-col" />
                                    <td className="unit" />
                                </>
                            )
                        ) : (
                            <>
                                <td className="data-col">-</td>
                                <td className="unit" />
                                <td className="data-col second-col">-</td>
                                <td className="unit" />
                            </>
                        )}
                    </tr>

                    <tr>
                        <th>Σ</th>
                        <td className="data-col">{round(grid, 1)}</td>
                        <td className="unit">kWh</td>
                        <td className="data-col second-col">{round((grid * KWH_PRICE) / 100, 2)}</td>
                        <td className="unit">€</td>
                    </tr>
                </tbody>
            </table>

            <Header size="medium">Gesamt</Header>
            <table className="ui definition table gauge unstackable">
                <tbody>
                    <tr>
                        <th>Autark</th>
                        <td className="data-col">{round(yearlyAutarky * 100)}</td>
                        <td className="unit">%</td>
                        <td className="data-col second-col" title="Eigenverbrauch">
                            {round(yearlyOwnConsumption * 100)}
                        </td>
                        <td className="unit">%</td>
                    </tr>
                    <tr>
                        <th>Gesamt</th>
                        <td className="data-col">{round(overallSolarSumKwh)}</td>
                        <td className="unit">kWh</td>
                        <td className="data-col second-col">{round(overallIncome)}</td>
                        <td className="unit">€</td>
                    </tr>
                    <tr>
                        <th>ROI</th>
                        <td className="data-col second-col">{round(remainingCost / avgDailyIncome / 365, 1)}</td>
                        <td className="unit">Jahre</td>
                        <td className="data-col">{round((overallIncome / INVEST_COST) * 100, 1)}</td>
                        <td className="unit">%</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};
