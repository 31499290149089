import GaugeChart from 'react-gauge-chart';

import { MAX_POWER } from '../utils/consts';

export const GaugeWrapper = ({ showCurrentData, currentPowerPercent, needleColor }) => {
    return (
        <GaugeChart
            animate={false}
            percent={currentPowerPercent}
            nrOfLevels={3}
            arcsLength={[0.1, 0.2, 0.7]}
            colors={showCurrentData ? ['#EA4228', '#F5CD19', '#41b91a'] : ['#e7e7e7', '#e7e7e7', '#e7e7e7']}
            needleColor={needleColor}
            needleBaseColor={needleColor}
            arcPadding={0.02}
            textColor="#000"
            formatTextValue={percent => (showCurrentData ? `${Math.round((percent / 100) * MAX_POWER)} W` : '')}
        />
    );
};
