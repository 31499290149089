import { getDbDate } from '../utils/date';
import { ONE_DAY } from '../utils/consts';
import { DEFAULT_DATE_RANGE } from '../hooks/useUpdateDateRange';
import { useRef, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

export const DateSelect = ({
    dateRange,
    dayData,
    resolution,
    updateDateRange,
    setResolution,
    scaleChart,
    setScaleChart,
    fullDay,
    setFullDay,
    showSecondary,
    setShowSecondary,
    showSecondaryConsumption,
    setShowSecondaryConsumption
}) => {
    const { viewMode, amount, scope, timestamp } = dateRange ?? DEFAULT_DATE_RANGE;
    const [showInput, setShowInput] = useState(false);
    const input = useRef(null);

    const handleDateChange = event => {
        updateDateRange({ timestamp: new Date(`${event.target.value}T00:00:00Z`).getTime() / 1_000 });
    };

    const toggleViewMode = () => {
        updateDateRange({ viewMode: viewMode === 'lines' ? 'bars+lines' : 'lines' });
    };

    const hasPreviousDay = dayData.at(0) && dayData.at(0).timestamp < timestamp;
    const hasNextDay = dayData.at(0) && dayData.at(-1).timestamp > timestamp;

    const scopeOptions = [
        { key: 'day', text: amount === 1 ? 'Tag' : 'Tage', value: 'day' },
        { key: 'week', text: amount === 1 ? 'Woche' : 'Wochen', value: 'week' },
        { key: 'month', text: amount === 1 ? 'Monat' : 'Monate', value: 'month' },
        { key: 'year', text: amount === 1 ? 'Jahr' : 'Jahre', value: 'year' }
    ];

    return (
        <div className="ui top attached menu">
            <div className="item desktop-only">
                <Dropdown
                    text={resolution + ' min'}
                    options={[5, 15, 30, 60].map(resolution => ({
                        key: resolution,
                        text: resolution,
                        value: resolution
                    }))}
                    upward={false}
                    inline
                    closeOnChange
                    closeOnEscape
                    onChange={(e, { value }) => setResolution(value)}
                />
            </div>

            <div className="item">
                <Dropdown
                    className="compressed"
                    text={amount.toString()}
                    options={new Array(31)
                        .fill()
                        .map((_, index) => ({ key: index, text: index + 1, value: index + 1 }))}
                    upward={false}
                    inline
                    closeOnChange
                    closeOnEscape
                    onChange={(e, { value }) => updateDateRange({ amount: parseInt(value) })}
                />
                <Dropdown
                    text={scopeOptions.find(option => option.value === scope).text}
                    options={scopeOptions}
                    upward={false}
                    inline
                    closeOnChange
                    closeOnEscape
                    onChange={(e, { value }) => updateDateRange({ scope: value })}
                />
            </div>

            <div className="item">
                <span>ab</span>

                <Button
                    /*className="desktop-only"*/
                    icon="arrow left"
                    compact={true}
                    disabled={false && !hasPreviousDay}
                    onClick={() =>
                        updateDateRange({
                            timestamp: timestamp - ONE_DAY
                        })
                    }
                />

                <div className="ui input">
                    <input
                        type="date"
                        value={getDbDate(timestamp)}
                        onChange={handleDateChange}
                        min={dayData.length !== 0 ? getDbDate(dayData.at(0).timestamp) : ''}
                        max={dayData.length !== 0 ? getDbDate(dayData.at(-1).timestamp) : ''}
                    />
                </div>

                <Button
                    className="desktop-only"
                    icon="calendar check outline"
                    compact={true}
                    onClick={() => updateDateRange({ timestamp: new Date().getTime() / 1_000 })}
                />

                <Button
                    /*className="desktop-only"*/
                    icon="arrow right"
                    compact={true}
                    disabled={false && !hasNextDay}
                    onClick={() =>
                        updateDateRange({
                            timestamp: timestamp + ONE_DAY
                        })
                    }
                />

                <Button
                    icon="undo"
                    onClick={() => {
                        setResolution(15);
                        updateDateRange({
                            ...DEFAULT_DATE_RANGE,
                            viewMode,
                            timestamp: new Date().getTime() / 1_000
                        });
                    }}
                />
            </div>

            <div className="item">
                <Button
                    icon={`zoom ${scaleChart ? 'in' : 'out'}`}
                    toggle
                    active={scaleChart}
                    onClick={() => setScaleChart(current => !current)}
                />
                <Button icon="clock" toggle active={fullDay} onClick={() => setFullDay(current => !current)} />
                <Button
                    icon="chart bar"
                    toggle
                    active={showSecondary}
                    onClick={() => setShowSecondary(current => !current)}
                />
                <Button
                    icon="plug"
                    toggle
                    active={showSecondaryConsumption}
                    disabled={!showSecondary}
                    onClick={() => setShowSecondaryConsumption(current => !current)}
                />
            </div>

            <div className="right menu">
                <div className="item desktop-only">
                    <Button icon={viewMode === 'bars+lines' ? 'expand' : 'compress'} onClick={toggleViewMode} />
                </div>
            </div>
        </div>
    );
};
