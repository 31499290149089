import { useCallback, useLayoutEffect, useState } from 'react';
import { getDbDate } from '../utils/date';
import { ONE_DAY } from '../utils/consts';

export const DEFAULT_DATE_RANGE = {
    viewMode: 'lines+bars',
    amount: 1,
    scope: 'day',
    timestamp: new Date().getTime() / 1_000
};

export const useUpdateDateRange = () => {
    const [dateRange, setDateRange] = useState();
    const [resolution, setResolution] = useState(15);

    const updateDateRange = useCallback(
        update => {
            setDateRange(current => {
                const base = {
                    ...DEFAULT_DATE_RANGE,
                    ...current,
                    ...update
                };

                const merged = {
                    ...base,
                    startTimestamp: getStartTimestamp(base.timestamp, base.amount, base.scope)
                };

                const { timestamp, viewMode, amount, scope } = merged;

                //console.log(amount, getDbDate(merged.timestamp) === getDbDate(new Date().getTime() / 1_000));

                if (scope !== 'day' || amount >= 7) {
                    setResolution(60);
                } else if (amount >= 5) {
                    setResolution(30);
                } else if (getDbDate(merged.timestamp) === getDbDate(new Date().getTime() / 1_000) && amount === 1) {
                    //console.log('set 5');
                    setResolution(5);
                } else {
                    //console.log('set 15');
                    setResolution(15);
                }

                window.history.pushState({}, '', `/chart/${viewMode}/${getDbDate(timestamp)}/${amount}/${scope}`);

                return merged;
            });
        },
        [setDateRange, setResolution]
    );

    useLayoutEffect(() => {
        const [_, viewMode, date, amount, scope] = document.location.pathname.slice(1).split('/');

        updateDateRange({
            viewMode: viewMode ?? DEFAULT_DATE_RANGE.viewMode,
            timestamp: date ? new Date(`${date}T00:00:00Z`).getTime() / 1_000 : DEFAULT_DATE_RANGE.timestamp,
            amount: amount ? parseInt(amount) : null ?? DEFAULT_DATE_RANGE.amount,
            scope: scope ?? DEFAULT_DATE_RANGE.scope
        });
    }, [updateDateRange]);

    const getStartTimestamp = (timestamp, amount, scope) => {
        let offset = 0;

        // eslint-disable-next-line default-case
        switch (scope) {
            case 'day':
                offset = amount * ONE_DAY;
                break;
            case 'week':
                offset = amount * 7 * ONE_DAY;
                break;
            case 'month':
                offset = amount * 30 * ONE_DAY;
                break;
            case 'year':
                offset = amount * 365 * ONE_DAY;
                break;
        }

        return timestamp - offset + ONE_DAY;
    };

    return { dateRange, setDateRange, resolution, setResolution, updateDateRange };
};
