import { useState } from 'react';
import classNames from 'classnames';
import { DateSelect } from './DateSelect';
import { LineChart } from './LineChart';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { LogTable } from './LogTable';

export const LineCharts = ({
    data,
    dataSecondary,
    viewMode,
    dateRange,
    setDateRange,
    resolution,
    setResolution,
    updateDateRange
}) => {
    const [fullDay, setFullDay] = useState(false);
    const [scaleChart, setScaleChart] = useState(true);
    const [showSecondary, setShowSecondary] = useState(true);
    const [showSecondaryConsumption, setShowSecondaryConsumption] = useState(false);
    const [showLog, setShowLog] = useState(false);

    const packetLosses = 0; /*logData
        .filter(item => item.timeDelta > 300 * 1.5)
        .reduce((sum, item) => sum + Math.round((item.timeDelta - 300) / 300), 0)*/

    return (
        <>
            <DateSelect
                dateRange={dateRange}
                setDateRange={setDateRange}
                dayData={[]}
                resolution={resolution}
                updateDateRange={updateDateRange}
                setResolution={setResolution}
                scaleChart={scaleChart}
                setScaleChart={setScaleChart}
                fullDay={fullDay}
                setFullDay={setFullDay}
                showSecondary={showSecondary}
                setShowSecondary={setShowSecondary}
                showSecondaryConsumption={showSecondaryConsumption}
                setShowSecondaryConsumption={setShowSecondaryConsumption}
            />
            <Segment className="bottom attached">
                <Grid.Row>
                    <Grid.Column className={classNames('line chart', { wide: viewMode.includes('bars') })}>
                        <div className={classNames({ primary: showSecondary, single: !showSecondary })}>
                            <LineChart data={data} fullDay={fullDay} scaleChart={scaleChart} showLabelsX={true} />
                        </div>
                        {showSecondary ? (
                            <>
                                <Header size="tiny">Letzte 7 Tage</Header>
                                <div className="secondary">
                                    <LineChart
                                        data={dataSecondary}
                                        fullDay={fullDay}
                                        scaleChart={showSecondaryConsumption ? scaleChart : false}
                                        showLabelsX={false}
                                    />
                                </div>
                            </>
                        ) : null}
                    </Grid.Column>
                    <Grid.Row>
                        <Grid.Column>
                            &nbsp;
                            <Header size="tiny" onClick={() => setShowLog(current => !current)}>
                                Log{' '}
                                <span>
                                    (Datensätze:
                                    {packetLosses === 0 ? ')' : ''}
                                </span>
                                {packetLosses > 0 ? (
                                    <>
                                        <span>/</span>
                                        <span>Paketverluste: {packetLosses})</span>
                                    </>
                                ) : null}
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Row>
            </Segment>
        </>
    );
};
