export const round = (value, precision, pretty) => {
    if (!value) return value;
    if (!precision) precision = 0;

    let result = value.toFixed(precision).replace('.', ',');

    const [decimals, fraction] = result.split(',');

    if (decimals.length > 3) {
        const suffix = fraction ? `,${fraction}` : '';
        result = `${decimals.slice(0, -3)}.${decimals.slice(-3)}${suffix}`;
    }

    if (pretty !== false) {
        if (result.endsWith(',00')) return result.slice(0, -3);
        if (result.endsWith(',0')) return result.slice(0, -2);
    }

    return result;
};
