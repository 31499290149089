import { useState } from 'react';
import { Button, Dropdown, Header, Segment } from 'semantic-ui-react';
import { BarChart } from './BarChart';
import classNames from 'classnames';

export const BarCharts = ({ dayData, updateDateRange, viewMode }) => {
    const [amount, setAmount] = useState(14);
    const [scope, setScope] = useState('days');
    const [showGrid, setShowGrid] = useState(false);

    const toggleViewMode = () => {
        updateDateRange({ viewMode: viewMode === 'bars' ? 'bars+lines' : 'bars' });
    };

    const scopeOptions = [
        { key: 'days', text: 'Tage', value: 'days' },
        { key: 'weeks', text: 'Wochen', value: 'weeks' },
        { key: 'months', text: 'Monate', value: 'months' }
    ];

    return (
        <>
            <div className="ui top attached menu">
                <div className="item">
                    <Dropdown
                        text={amount.toString()}
                        options={[7, 14, 30, 60, 90].map(resolution => ({
                            key: resolution,
                            text: resolution,
                            value: resolution
                        }))}
                        upward={false}
                        inline
                        closeOnChange
                        closeOnEscape
                        onChange={(e, { value }) => setAmount(value)}
                    />

                    <Dropdown
                        text={scopeOptions.find(option => option.value === scope).text}
                        options={scopeOptions}
                        upward={false}
                        inline
                        closeOnChange
                        closeOnEscape
                        onChange={(e, { value }) => setScope(value)}
                    />
                </div>

                <div className="item">
                    <Button icon="plug" toggle active={showGrid} onClick={() => setShowGrid(current => !current)} />
                </div>

                <div className="right menu">
                    <div className="item desktop-only">
                        <Button icon={viewMode === 'bars+lines' ? 'expand' : 'compress'} onClick={toggleViewMode} />
                    </div>
                </div>
            </div>

            <Segment className="bar chart bottom attached">
                <div className={classNames({ primary: scope === 'days', single: scope !== 'days' })}>
                    <BarChart
                        dayData={dayData}
                        amount={amount}
                        scope={scope}
                        showGrid={showGrid}
                        updateDateRange={updateDateRange}
                    />
                </div>

                {scope === 'days' ? (
                    <>
                        <Header size="tiny">Letzte Wochen {/*Letzte 12 Wochen*/}</Header>
                        <div className="secondary">
                            <BarChart
                                dayData={dayData}
                                amount={12}
                                scope="weeks"
                                showGrid={showGrid}
                                updateDateRange={updateDateRange}
                            />
                        </div>

                        <Header size="tiny">Letzte Monate {/*Jahresvergleich*/}</Header>
                        <div className="secondary">
                            <BarChart
                                dayData={dayData}
                                amount={12}
                                scope="months"
                                showGrid={showGrid}
                                updateDateRange={updateDateRange}
                            />
                        </div>
                    </>
                ) : null}
            </Segment>
        </>
    );
};
