const timeTable = {
    '01-01': ['07:22', '15:35'],
    '01-02': ['07:22', '15:36'],
    '01-03': ['07:21', '15:37'],
    '01-04': ['07:21', '15:38'],
    '01-05': ['07:21', '15:39'],
    '01-06': ['07:21', '15:40'],
    '01-07': ['07:20', '15:41'],
    '01-08': ['07:20', '15:43'],
    '01-09': ['07:20', '15:44'],
    '01-10': ['07:19', '15:45'],
    '01-11': ['07:19', '15:47'],
    '01-12': ['07:18', '15:48'],
    '01-13': ['07:17', '15:49'],
    '01-14': ['07:17', '15:51'],
    '01-15': ['07:16', '15:52'],
    '01-16': ['07:15', '15:54'],
    '01-17': ['07:14', '15:55'],
    '01-18': ['07:13', '15:57'],
    '01-19': ['07:13', '15:58'],
    '01-20': ['07:12', '16:00'],
    '01-21': ['07:11', '16:01'],
    '01-22': ['07:10', '16:03'],
    '01-23': ['07:08', '16:05'],
    '01-24': ['07:07', '16:06'],
    '01-25': ['07:06', '16:08'],
    '01-26': ['07:05', '16:10'],
    '01-27': ['07:04', '16:11'],
    '01-28': ['07:02', '16:13'],
    '01-29': ['07:01', '16:15'],
    '01-30': ['07:00', '16:16'],
    '01-31': ['06:58', '16:18'],
    '02-01': ['06:57', '16:20'],
    '02-02': ['06:56', '16:21'],
    '02-03': ['06:54', '16:23'],
    '02-04': ['06:53', '16:25'],
    '02-05': ['06:51', '16:26'],
    '02-06': ['06:49', '16:28'],
    '02-07': ['06:48', '16:30'],
    '02-08': ['06:46', '16:32'],
    '02-09': ['06:45', '16:33'],
    '02-10': ['06:43', '16:35'],
    '02-11': ['06:41', '16:37'],
    '02-12': ['06:39', '16:39'],
    '02-13': ['06:38', '16:40'],
    '02-14': ['06:36', '16:42'],
    '02-15': ['06:34', '16:44'],
    '02-16': ['06:32', '16:45'],
    '02-17': ['06:30', '16:47'],
    '02-18': ['06:28', '16:49'],
    '02-19': ['06:27', '16:51'],
    '02-20': ['06:25', '16:52'],
    '02-21': ['06:23', '16:54'],
    '02-22': ['06:21', '16:56'],
    '02-23': ['06:19', '16:57'],
    '02-24': ['06:17', '16:59'],
    '02-25': ['06:15', '17:01'],
    '02-26': ['06:13', '17:02'],
    '02-27': ['06:11', '17:04'],
    '02-28': ['06:09', '17:06'],
    '02-29': ['06:07', '17:07'],
    '03-01': ['06:07', '17:07'],
    '03-02': ['06:05', '17:09'],
    '03-03': ['06:03', '17:11'],
    '03-04': ['06:01', '17:12'],
    '03-05': ['05:58', '17:14'],
    '03-06': ['05:56', '17:16'],
    '03-07': ['05:54', '17:17'],
    '03-08': ['05:52', '17:19'],
    '03-09': ['05:50', '17:21'],
    '03-10': ['05:48', '17:22'],
    '03-11': ['05:46', '17:24'],
    '03-12': ['05:44', '17:26'],
    '03-13': ['05:41', '17:27'],
    '03-14': ['05:39', '17:29'],
    '03-15': ['05:37', '17:30'],
    '03-16': ['05:35', '17:32'],
    '03-17': ['05:33', '17:34'],
    '03-18': ['05:31', '17:35'],
    '03-19': ['05:28', '17:37'],
    '03-20': ['05:26', '17:38'],
    '03-21': ['05:24', '17:40'],
    '03-22': ['05:22', '17:42'],
    '03-23': ['05:20', '17:43'],
    '03-24': ['05:17', '17:45'],
    '03-25': ['05:15', '17:46'],
    '03-26': ['05:13', '17:48'],
    '03-27': ['05:11', '17:50'],
    '03-28': ['05:09', '17:51'],
    '03-29': ['05:07', '17:53'],
    '03-30': ['05:04', '17:54'],
    '03-31': ['05:02', '17:56'],
    '04-01': ['05:00', '17:57'],
    '04-02': ['04:58', '17:59'],
    '04-03': ['04:56', '18:01'],
    '04-04': ['04:53', '18:02'],
    '04-05': ['04:51', '18:04'],
    '04-06': ['04:49', '18:05'],
    '04-07': ['04:47', '18:07'],
    '04-08': ['04:45', '18:09'],
    '04-09': ['04:43', '18:10'],
    '04-10': ['04:41', '18:12'],
    '04-11': ['04:39', '18:13'],
    '04-12': ['04:36', '18:15'],
    '04-13': ['04:34', '18:16'],
    '04-14': ['04:32', '18:18'],
    '04-15': ['04:30', '18:20'],
    '04-16': ['04:28', '18:21'],
    '04-17': ['04:26', '18:23'],
    '04-18': ['04:24', '18:24'],
    '04-19': ['04:22', '18:26'],
    '04-20': ['04:20', '18:27'],
    '04-21': ['04:18', '18:29'],
    '04-22': ['04:16', '18:31'],
    '04-23': ['04:14', '18:32'],
    '04-24': ['04:12', '18:34'],
    '04-25': ['04:10', '18:35'],
    '04-26': ['04:08', '18:37'],
    '04-27': ['04:06', '18:38'],
    '04-28': ['04:05', '18:40'],
    '04-29': ['04:03', '18:42'],
    '04-30': ['04:01', '18:43'],
    '05-01': ['03:59', '18:45'],
    '05-02': ['03:57', '18:46'],
    '05-03': ['03:56', '18:48'],
    '05-04': ['03:54', '18:49'],
    '05-05': ['03:52', '18:51'],
    '05-06': ['03:50', '18:52'],
    '05-07': ['03:49', '18:54'],
    '05-08': ['03:47', '18:55'],
    '05-09': ['03:46', '18:57'],
    '05-10': ['03:44', '18:58'],
    '05-11': ['03:42', '19:00'],
    '05-12': ['03:41', '19:01'],
    '05-13': ['03:39', '19:03'],
    '05-14': ['03:38', '19:04'],
    '05-15': ['03:37', '19:06'],
    '05-16': ['03:35', '19:07'],
    '05-17': ['03:34', '19:08'],
    '05-18': ['03:33', '19:10'],
    '05-19': ['03:31', '19:11'],
    '05-20': ['03:30', '19:13'],
    '05-21': ['03:29', '19:14'],
    '05-22': ['03:28', '19:15'],
    '05-23': ['03:26', '19:17'],
    '05-24': ['03:25', '19:18'],
    '05-25': ['03:24', '19:19'],
    '05-26': ['03:23', '19:20'],
    '05-27': ['03:22', '19:21'],
    '05-28': ['03:21', '19:23'],
    '05-29': ['03:21', '19:24'],
    '05-30': ['03:20', '19:25'],
    '05-31': ['03:19', '19:26'],
    '06-01': ['03:18', '19:27'],
    '06-02': ['03:17', '19:28'],
    '06-03': ['03:17', '19:29'],
    '06-04': ['03:16', '19:30'],
    '06-05': ['03:16', '19:31'],
    '06-06': ['03:15', '19:32'],
    '06-07': ['03:14', '19:33'],
    '06-08': ['03:14', '19:34'],
    '06-09': ['03:14', '19:34'],
    '06-10': ['03:13', '19:35'],
    '06-11': ['03:13', '19:36'],
    '06-12': ['03:13', '19:36'],
    '06-13': ['03:13', '19:37'],
    '06-14': ['03:12', '19:38'],
    '06-15': ['03:12', '19:38'],
    '06-16': ['03:12', '19:39'],
    '06-17': ['03:12', '19:39'],
    '06-18': ['03:12', '19:39'],
    '06-19': ['03:12', '19:40'],
    '06-20': ['03:13', '19:40'],
    '06-21': ['03:13', '19:40'],
    '06-22': ['03:13', '19:41'],
    '06-23': ['03:13', '19:41'],
    '06-24': ['03:14', '19:41'],
    '06-25': ['03:14', '19:41'],
    '06-26': ['03:14', '19:41'],
    '06-27': ['03:15', '19:41'],
    '06-28': ['03:15', '19:41'],
    '06-29': ['03:16', '19:41'],
    '06-30': ['03:16', '19:40'],
    '07-01': ['03:17', '19:40'],
    '07-02': ['03:18', '19:40'],
    '07-03': ['03:18', '19:39'],
    '07-04': ['03:19', '19:39'],
    '07-05': ['03:20', '19:39'],
    '07-06': ['03:21', '19:38'],
    '07-07': ['03:22', '19:38'],
    '07-08': ['03:23', '19:37'],
    '07-09': ['03:24', '19:36'],
    '07-10': ['03:25', '19:36'],
    '07-11': ['03:25', '19:35'],
    '07-12': ['03:27', '19:34'],
    '07-13': ['03:28', '19:33'],
    '07-14': ['03:29', '19:33'],
    '07-15': ['03:30', '19:32'],
    '07-16': ['03:31', '19:31'],
    '07-17': ['03:32', '19:30'],
    '07-18': ['03:33', '19:29'],
    '07-19': ['03:34', '19:28'],
    '07-20': ['03:36', '19:27'],
    '07-21': ['03:37', '19:26'],
    '07-22': ['03:38', '19:24'],
    '07-23': ['03:39', '19:23'],
    '07-24': ['03:41', '19:22'],
    '07-25': ['03:42', '19:21'],
    '07-26': ['03:43', '19:19'],
    '07-27': ['03:45', '19:18'],
    '07-28': ['03:46', '19:16'],
    '07-29': ['03:48', '19:15'],
    '07-30': ['03:49', '19:14'],
    '07-31': ['03:50', '19:12'],
    '08-01': ['03:52', '19:11'],
    '08-02': ['03:53', '19:09'],
    '08-03': ['03:55', '19:07'],
    '08-04': ['03:56', '19:06'],
    '08-05': ['03:57', '19:04'],
    '08-06': ['03:59', '19:03'],
    '08-07': ['04:00', '19:01'],
    '08-08': ['04:02', '18:59'],
    '08-09': ['04:03', '18:57'],
    '08-10': ['04:05', '18:56'],
    '08-11': ['04:06', '18:54'],
    '08-12': ['04:08', '18:52'],
    '08-13': ['04:09', '18:50'],
    '08-14': ['04:11', '18:48'],
    '08-15': ['04:12', '18:46'],
    '08-16': ['04:14', '18:45'],
    '08-17': ['04:15', '18:43'],
    '08-18': ['04:17', '18:41'],
    '08-19': ['04:18', '18:39'],
    '08-20': ['04:20', '18:37'],
    '08-21': ['04:21', '18:35'],
    '08-22': ['04:23', '18:33'],
    '08-23': ['04:24', '18:31'],
    '08-24': ['04:26', '18:29'],
    '08-25': ['04:27', '18:27'],
    '08-26': ['04:29', '18:25'],
    '08-27': ['04:30', '18:23'],
    '08-28': ['04:32', '18:20'],
    '08-29': ['04:33', '18:18'],
    '08-30': ['04:35', '18:16'],
    '08-31': ['04:36', '18:14'],
    '09-01': ['04:38', '18:12'],
    '09-02': ['04:39', '18:10'],
    '09-03': ['04:41', '18:08'],
    '09-04': ['04:42', '18:06'],
    '09-05': ['04:44', '18:03'],
    '09-06': ['04:45', '18:01'],
    '09-07': ['04:47', '17:59'],
    '09-08': ['04:48', '17:57'],
    '09-09': ['04:50', '17:55'],
    '09-10': ['04:51', '17:52'],
    '09-11': ['04:53', '17:50'],
    '09-12': ['04:54', '17:48'],
    '09-13': ['04:56', '17:46'],
    '09-14': ['04:57', '17:44'],
    '09-15': ['04:59', '17:41'],
    '09-16': ['05:00', '17:39'],
    '09-17': ['05:02', '17:37'],
    '09-18': ['05:03', '17:35'],
    '09-19': ['05:05', '17:33'],
    '09-20': ['05:06', '17:30'],
    '09-21': ['05:08', '17:28'],
    '09-22': ['05:09', '17:26'],
    '09-23': ['05:11', '17:24'],
    '09-24': ['05:12', '17:22'],
    '09-25': ['05:14', '17:19'],
    '09-26': ['05:15', '17:17'],
    '09-27': ['05:17', '17:15'],
    '09-28': ['05:18', '17:13'],
    '09-29': ['05:20', '17:10'],
    '09-30': ['05:21', '17:08'],
    '10-01': ['05:23', '17:06'],
    '10-02': ['05:24', '17:04'],
    '10-03': ['05:26', '17:02'],
    '10-04': ['05:28', '17:00'],
    '10-05': ['05:29', '16:57'],
    '10-06': ['05:31', '16:55'],
    '10-07': ['05:32', '16:53'],
    '10-08': ['05:34', '16:51'],
    '10-09': ['05:35', '16:49'],
    '10-10': ['05:37', '16:47'],
    '10-11': ['05:38', '16:45'],
    '10-12': ['05:40', '16:43'],
    '10-13': ['05:42', '16:40'],
    '10-14': ['05:43', '16:38'],
    '10-15': ['05:45', '16:36'],
    '10-16': ['05:46', '16:34'],
    '10-17': ['05:48', '16:32'],
    '10-18': ['05:50', '16:30'],
    '10-19': ['05:51', '16:28'],
    '10-20': ['05:53', '16:26'],
    '10-21': ['05:55', '16:24'],
    '10-22': ['05:56', '16:22'],
    '10-23': ['05:58', '16:20'],
    '10-24': ['05:59', '16:19'],
    '10-25': ['06:01', '16:17'],
    '10-26': ['06:03', '16:15'],
    '10-27': ['06:04', '16:13'],
    '10-28': ['06:06', '16:11'],
    '10-29': ['06:08', '16:09'],
    '10-30': ['06:09', '16:08'],
    '10-31': ['06:11', '16:06'],
    '11-01': ['06:13', '16:04'],
    '11-02': ['06:14', '16:02'],
    '11-03': ['06:16', '16:01'],
    '11-04': ['06:18', '15:59'],
    '11-05': ['06:19', '15:57'],
    '11-06': ['06:21', '15:56'],
    '11-07': ['06:23', '15:54'],
    '11-08': ['06:24', '15:53'],
    '11-09': ['06:26', '15:51'],
    '11-10': ['06:28', '15:50'],
    '11-11': ['06:29', '15:48'],
    '11-12': ['06:31', '15:47'],
    '11-13': ['06:32', '15:46'],
    '11-14': ['06:34', '15:44'],
    '11-15': ['06:36', '15:43'],
    '11-16': ['06:37', '15:42'],
    '11-17': ['06:39', '15:40'],
    '11-18': ['06:41', '15:39'],
    '11-19': ['06:42', '15:38'],
    '11-20': ['06:44', '15:37'],
    '11-21': ['06:45', '15:36'],
    '11-22': ['06:47', '15:35'],
    '11-23': ['06:48', '15:34'],
    '11-24': ['06:50', '15:33'],
    '11-25': ['06:51', '15:32'],
    '11-26': ['06:53', '15:31'],
    '11-27': ['06:54', '15:30'],
    '11-28': ['06:56', '15:30'],
    '11-29': ['06:57', '15:29'],
    '11-30': ['06:58', '15:28'],
    '12-01': ['07:00', '15:28'],
    '12-02': ['07:01', '15:27'],
    '12-03': ['07:02', '15:27'],
    '12-04': ['07:04', '15:26'],
    '12-05': ['07:05', '15:26'],
    '12-06': ['07:06', '15:25'],
    '12-07': ['07:07', '15:25'],
    '12-08': ['07:08', '15:25'],
    '12-09': ['07:09', '15:25'],
    '12-10': ['07:10', '15:25'],
    '12-11': ['07:11', '15:24'],
    '12-12': ['07:12', '15:24'],
    '12-13': ['07:13', '15:24'],
    '12-14': ['07:14', '15:24'],
    '12-15': ['07:15', '15:25'],
    '12-16': ['07:16', '15:25'],
    '12-17': ['07:16', '15:25'],
    '12-18': ['07:17', '15:25'],
    '12-19': ['07:18', '15:26'],
    '12-20': ['07:18', '15:26'],
    '12-21': ['07:19', '15:26'],
    '12-22': ['07:19', '15:27'],
    '12-23': ['07:20', '15:27'],
    '12-24': ['07:20', '15:28'],
    '12-25': ['07:21', '15:29'],
    '12-26': ['07:21', '15:29'],
    '12-27': ['07:21', '15:30'],
    '12-28': ['07:21', '15:31'],
    '12-29': ['07:22', '15:32'],
    '12-30': ['07:22', '15:33'],
    '12-31': ['07:22', '15:34']
};

export const getSunriseAndSet = (timestamp, offset) => {
    const date = timestamp ? new Date(timestamp * 1_000) : new Date();

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const [up, down] = timeTable[`${month}-${day}`];

    const timezoneOffsetMs = getTimezoneOffsetMs();

    const upTimestamp = (new Date(`${year}-${month}-${day}T${up}:00Z`).getTime() + timezoneOffsetMs) / 1_000;
    const downTimestamp = (new Date(`${year}-${month}-${day}T${down}:00Z`).getTime() + timezoneOffsetMs) / 1_000;

    return [upTimestamp - (offset ?? 0) * 60, downTimestamp + (offset ?? 0) * 60];
};

const getTimezoneOffsetMs = () => new Date().getTimezoneOffset() * 60 * 1_000 * -1;

export const hasSun = (timestamp = (new Date().getTime() + getTimezoneOffsetMs()) / 1_000, offset = 15) => {
    const [up, down] = getSunriseAndSet(timestamp, offset);
    return timestamp > up && timestamp < down;
};
