import './styles/main.scss';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { handleAxiosError } from './utils/axios';
import 'chart.js/auto';
import { getDbDate, getFullDate } from './utils/date';
import { Gauge } from './components/Gauge';
import { LineCharts } from './components/LineCharts';
import { ACCESS_TOKEN, BASE_URL_LOCAL, BASE_URL_REMOTE, ONE_DAY } from './utils/consts';
import { BarCharts } from './components/BarCharts';
import { DEFAULT_DATE_RANGE, useUpdateDateRange } from './hooks/useUpdateDateRange';
import { Container, Grid, Segment } from 'semantic-ui-react';

axios.defaults.baseURL =
    process.env.NODE_ENV === 'production' || process.env.REACT_APP_BACKEND === 'remote'
        ? BASE_URL_REMOTE
        : BASE_URL_LOCAL;
axios.defaults.headers.accessToken = ACCESS_TOKEN;

function App() {
    const { dateRange, setDateRange, updateDateRange, resolution, setResolution } = useUpdateDateRange();

    const [data, setData] = useState({});
    const [dataSecondary, setDataSecondary] = useState([]);
    const [dataDay, setDataDay] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [lastFocusTime, setLastFocusTime] = useState(new Date().getTime());

    const { viewMode, timestamp, startTimestamp } = dateRange ?? {};

    const today = getDbDate(new Date().getTime());

    const loadData = useCallback(() => {
        if (!timestamp) return;

        axios(
            `/watt/${getDbDate(startTimestamp)}${
                startTimestamp !== timestamp ? `/${getDbDate(timestamp)}` : ''
            }?resolution=${resolution}`
        )
            .then(({ data }) => setData(data))
            .catch(handleAxiosError);
    }, [startTimestamp, timestamp, resolution]);

    const loadDataSecondary = useCallback(() => {
        if (!today) return; // obsolete, silence dependency warning and rerender every new day

        const lastWeekStart = getDbDate(new Date().getTime() / 1_000);
        const lastWeekEnd = getDbDate(new Date().getTime() / 1_000 - ONE_DAY * 6);

        axios(`/watt/${lastWeekEnd}/${lastWeekStart}?resolution=120`)
            .then(({ data }) => setDataSecondary(data))
            .catch(handleAxiosError);
    }, [today]);

    const loadDataDay = useCallback(() => {
        axios('day')
            .then(({ data }) => setDataDay(data))
            .catch(handleAxiosError);
    }, []);

    useLayoutEffect(() => {
        loadData();
        loadDataSecondary();
        loadDataDay();
    }, [loadData, loadDataSecondary, loadDataDay]);

    const handleFocus = useCallback(() => {
        if (new Date().getTime() - lastFocusTime > 2 * 60 * 60 * 1_000) {
            updateDateRange({ ...DEFAULT_DATE_RANGE, timestamp: new Date().getTime() / 1_000 });
        } else {
            loadData();
            loadDataSecondary();
            loadDataDay();
        }

        setLastFocusTime(new Date().getTime());
    }, [lastFocusTime, loadData, loadDataSecondary, loadDataDay]);

    useEffect(() => {
        window.addEventListener('focus', handleFocus);
        return () => window.removeEventListener('focus', handleFocus);
    }, [handleFocus]);

    useEffect(() => {
        const updateDataTimer = setInterval(() => {
            loadData();
            loadDataSecondary();
        }, 0.5 * 60 * 1_000);

        return () => clearInterval(updateDataTimer);
    }, [loadData, loadDataSecondary]);

    return (
        <Container fluid className="App">
            <h1>
                <img src="/logo.svg" alt="" />
                Solar Monitor
            </h1>

            <Grid columns={2} stackable>
                <Grid.Row>
                    <Grid.Column width={3} className="gauge-column">
                        <Segment>{<Gauge data={data} dataDay={dataDay} />}</Segment>
                    </Grid.Column>

                    {viewMode && viewMode.includes('lines') ? (
                        <Grid.Column width={viewMode && !viewMode.includes('bars') ? 13 : 9}>
                            <LineCharts
                                data={data && data.watt ? data.watt : []}
                                dataSecondary={dataSecondary && dataSecondary.watt ? dataSecondary.watt : {}}
                                viewMode={viewMode}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                resolution={resolution}
                                updateDateRange={updateDateRange}
                                setResolution={setResolution}
                            />
                        </Grid.Column>
                    ) : null}

                    {viewMode && viewMode.includes('bars') ? (
                        <Grid.Column width={viewMode && !viewMode.includes('lines') ? 13 : 4}>
                            {<BarCharts dayData={dataDay} viewMode={viewMode} updateDateRange={updateDateRange} />}
                        </Grid.Column>
                    ) : null}
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default App;
